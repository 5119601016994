import {environment as config} from '../../../../environments/environment';
import {Injectable} from '@angular/core';
import {RedirectStrategy} from '../redirect/redirect-strategy';
import {TaraAuthRedirectStrategy} from './tara-auth-redirect-strategy';
import {TaraLogoutRedirectStrategy} from './tara-logout-redirect-strategy';
import {LocationService} from '../../services/location.service';
import {IdpAuthService} from '../idp-auth.service';
import {UrlBuilder} from '../../utils/url-builder';
import {TokenAuthService} from '../token-auth.service';
import {IdpCode} from '../idp-code';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TaraAuthService extends IdpAuthService {

  private taraLogoutRedirectStrategy: RedirectStrategy;

  private taraAuthRedirectStrategy: RedirectStrategy;

  constructor(protected http: HttpClient,
              protected tokenAuthService: TokenAuthService,
              protected locationService: LocationService) {
    super(tokenAuthService);
  }

  private init() {
    this.taraAuthRedirectStrategy = new TaraAuthRedirectStrategy(config, this.locationService.getSiteUrl());
    this.taraLogoutRedirectStrategy = new TaraLogoutRedirectStrategy(config);
  }

  requestAuthentication(params?: any): void {
    if (!this.taraAuthRedirectStrategy) {
      this.init();
    }
    this.taraAuthRedirectStrategy.redirect(params);
  }

  authenticationCallback(urlParams?: any) {
    let params = {};
    if (urlParams) {
      params = Object.assign(params, urlParams);
    }

    window.location.href = UrlBuilder.build(config.apiUrl, '/tara/login', params);
  }

  doLogout(params?: any): Promise<boolean> {
    if (!this.taraLogoutRedirectStrategy) {
      this.init();
    }
    return this.taraLogoutRedirectStrategy.redirect({token: params.token});
  }

  getIdp(): IdpCode {
    return IdpCode.TARA;
  }

  switchTo(idp: IdpCode) {
  }

  reset(): void {
  }

}
